body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, div, span, applet, object, iframe, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0 none;
    vertical-align: middle;
    background: transparent
}

ol, ul {
    list-style: none;
    margin: 0;
}

hr, legend {
    display: none;
    height: 0px;
}

a {
    text-decoration: none;
    color: #4c4c4c;
    cursor: pointer;
}

/* cursor 추가 by mjma*/
a:hover {
    text-decoration: none;
    color: #4c4c4c;
}

caption {
    font-size: 0;
    color: transparent;
    text-indent: -9999px;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
}

label, input, select, button {
    vertical-align: middle
}

img {
    vertical-align: top;
    border: 0;
}

em {
    font-style: normal;
    color: #0081d8;
}

h1, h2, h3, h4, h5 {
    margin: 0;
    padding: 0;
}

.access {
    overflow: hidden !important;
    visibility: hidden !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 0 !important;
    height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    font: 0/0 Arial !important;
}

.tm10 {
    margin-top: 10px;
}

.tm15 {
    margin-top: 15px;
}

.tm20 {
    margin-top: 20px;
}

.tm25 {
    margin-top: 25px;
}

.tm30 {
    margin-top: 30px;
}

.tm40 {
    margin-top: 40px;
}

.tm50 {
    margin-top: 50px;
}

.tm60 {
    margin-top: 66px;
}

.bm20 {
    margin-bottom: 20px;
}

.bm30 {
    margin-bottom: 30px;
}

#wrapper {
  width: 1264px;
  /*width:100%;*/
  overflow: hidden; }

#auth_container_wrap {
  position: relative;
  background-color: #fff; }

#auth_container_wrap.wd_412 {
  width: 412px;
  height: 536px; }

.auth_con {
  padding-top: 32px;
  margin: 0 40px; }

.auth_con h1 {
  padding-bottom: 30px; }

.auth_con .notice {
  padding: 40px 0;
  border-top: 1px solid #cacaca;
  font-size: 15px;
  font-weight: bold;
  color: #8a8a8a;
  line-height: 20px; }

.auth_con .b_logo {
  position: absolute;
  left: 30px;
  bottom: 3%; }

.auth_con .btn_pd {
  padding-top: 45px; }

.auth_con .rp_pd {
  padding-top: 10px; }

.btn_area span {
  text-indent: -1px;
  color: transparent;
  font-size: 0;
  overflow: hidden;
  display: inline-block;
  *zoom: 1;
  background: transparent url(/static/media/btn_img.83d50fab.png) no-repeat; }

.btn_area span.close_btn {
  background-position: -386px -245px;
  width: 222px;
  height: 45px;
  cursor: pointer; }

