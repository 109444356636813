#wrapper {
  width: 1264px; /*width:100%;*/
  overflow: hidden;
}

#auth_container_wrap {
  position: relative;
  background-color: #fff;
}

#auth_container_wrap.wd_412 {
  width: 412px;
  height: 536px
}

.auth_con {
  padding-top: 32px;
  margin: 0 40px;
}

.auth_con h1 {
  padding-bottom: 30px;
}

.auth_con .notice {
  padding: 40px 0;
  border-top: 1px solid #cacaca;
  font-size: 15px;
  font-weight: bold;
  color: #8a8a8a;
  line-height: 20px;
}

.auth_con .b_logo {
  position: absolute;
  left: 30px;
  bottom: 3%;
}

.auth_con .btn_pd {
  padding-top: 45px;
}

.auth_con .rp_pd {
  padding-top: 10px;
}

.btn_area span {
  text-indent: -1px;
  color: transparent;
  font-size: 0;
  overflow: hidden;
  display: inline-block;
  *zoom: 1;
  background: transparent url('../../assets/image/btn_img.png') no-repeat;
}

.btn_area span.close_btn {
  background-position: -386px -245px;
  width: 222px;
  height: 45px;
  cursor: pointer;
}